<template>
  <div
    ref="container"
    class="user-top-bar-avatar mdc-menu-surface--anchor" >

    <div
      ref="picture"
      class="roundPicture"
      :style="pictureStyle"
      @click="clicked">

      <i class="material-icons" v-show="!userProfilePicture">
      person
      </i>
    </div>

    <div ref="menu"
      class="mdc-menu mdc-menu-surface" tabindex="-1">
      <ul class="mdc-list" role="menu" aria-hidden="true" aria-orientation="vertical">

        <li class="mdc-list-item static" role="menuitem" v-if="userProfile"
          @click="performLogout">
          <h4>{{tLogoutText}}</h4>
        </li>
        <li class="mdc-list-item static" role="menuitem" v-else
          @click="performLogin">
          <h4>{{tLoginText}}</h4>
        </li>

        <!-- @slot Custom menu elements slot:

        <li class="mdc-list-item">..</li>-->
        <slot></slot>

      </ul>
    </div>
  </div>
</template>

<script>
import LoginMixin from '@paiuolo/django-sso-vue-mixins/mixins/LoginMixin'
import LogoutMixin from '@paiuolo/django-sso-vue-mixins/mixins/LogoutMixin'

import FetchUserProfileMixin from '@paiuolo/django-sso-vue-mixins/mixins/FetchUserProfileMixin'
import UserProfileMixin from '@paiuolo/django-sso-vue-mixins/mixins/UserProfileMixin'

import { MDCMenu, Corner } from '@material/menu/dist/mdc.menu'

export default {
  name: 'UserTopBarAvatar',
  mixins: [
    LoginMixin,
    LogoutMixin,
    FetchUserProfileMixin,
    UserProfileMixin
  ],
  props: {
    /**
     * Login redirect url
     */
    loginUrl: {
      type: String,
      default: '/login/'
    },
    /**
     * Logout POST url
     */
    logoutUrl: {
      type: String,
      default: '/api/v1/auth/logout/'
    },
    auto: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: null
    },
    loginText: {
      type: String,
      default: 'Login'
    },
    logoutText: {
      type: String,
      default: 'Logout'
    }
  },
  data () {
    return {
      menu: null
    }
  },
  mounted () {
    this.menu = new MDCMenu(this.$refs.menu)
    this.menu.setAnchorCorner(Corner.BOTTOM_LEFT)

    if (this.auto) {
      this.fetchUserProfile()
    }
  },
  computed: {
    pictureStyle () {
      let bgc = this.userProfile ? 'transparent' : '#0091EA'
      let style = {
        'background-color': this.backgroundColor || bgc
      }

      if (this.userProfilePicture) {
        style['background-image'] = 'url(\'' + this.userProfilePicture + '\')'
      }

      return style
    },
    tLoginText () {
      return this.gettext(this.loginText)
    },
    tLogoutText () {
      return this.gettext(this.logoutText)
    }
  },
  methods: {
    /** Open/close dropdown menu
    * @event click
    * @type {Event}
    */
    clicked () {
      this.menu.open = !this.menu.open
      if (this.menu.open) {
        this.$emit('menuOpened')
      } else {
        this.$emit('menuClosed')
      }
    }
  },
  watch: {
    userProfile (n) {
      if (n) {
        this.ssoUserId = n.sso_id
      }
    },
    auto (n) {
      if (n) {
        this.fetchUserProfile()
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../../scss/theme";

$selected: #ff4081;

@import "@material/menu/mdc-menu";
@import "@material/menu-surface/mdc-menu-surface";

.user-top-bar-avatar {
  position: relative;
  display: inline-block;

  align-items: center;
  justify-content: center;
  cursor: pointer;

  ul, li {
    list-style-type: none;
    padding: 0;
    outline: none;
  }

  ul {
    margin: 0;
  }

  li > * {
    width: 100%;
    box-sizing: border-box;
    padding: 12px 0;
    margin: 0;
    text-align: center;
  }
  li.static > * {
    padding: 16px 0;
  }

  li.static h4 {
    font-weight: normal;
  }

  .roundPicture {
    text-align: center;

    width: 24px;
    height: 24px;
    line-height: 24px;
    padding: 4px;

    @media (min-width: $break-phone){
      width: 36px;
      height: 36px;
      line-height: 36px;
      padding: 6px;
    }

    .material-icons {
      color: #fff;

      font-size: 24px;

      @media (min-width: $break-phone){
        font-size: 36px;
      }
    }
  }
  
  .segnala-top-button {
    width: 160px;
    padding: 24px 8px;
    text-align: center;

    > a {
      width: 100%;
      color: #333;
    }
  }
}

</style>
