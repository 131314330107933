import Vue from 'vue'
import axios from 'axios'
import VueCustomElement from 'vue-custom-element'

import VueGettext from '@paiuolo/pai-vue-gettext/plugins/VueGettext'

import UserTopBarAvatar from './components/entities/user/UserTopBarAvatar'


axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

Vue.prototype.$http = axios
Vue.config.productionTip = false

Vue.use(VueCustomElement)
Vue.use(VueGettext)

Vue.customElement('user-top-bar-avatar', UserTopBarAvatar)
